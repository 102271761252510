.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}
.invalid textarea {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}
  
.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}
  
.invalid textarea:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.invalid select {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid select:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

.srv-validation-message {
  color: #b40e0e;
}